// Color variables
$white: #ffffff;
$danger: #D9534F;
$success: #5CB85C;
$warning: #f7b543;
$secondary: #3c89d6;
$primary: #1b4e81;
$lightPrimary: #677482;
$darkPrimary: #454e57;
$lightDark: #495057;
$lightGray: #b6b6b6;
$primaryLight : #d1f3ff;
$lightdark5: #555555;
$info: #86deff;
$purple: #715ded;
$lightgray: #dddddd;
$pink: #e866ff;
$inverse: #212121;
$light: #eeeeee;
$lightdark3: #333333;
$lightdark4: #444444;
$light5: #f5f5f5;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark7: #797979;
$lightdark-alt: #cccccc;
$dark: #2a323c;
$muted: #898989;
$primaryDark: #143a61;

$font-primary: 'PT Sans Caption', sans-serif;
$font-secondary: 'Roboto', sans-serif;


// Width variables
$width: 100%;


// Height variables
$height: 100%;
