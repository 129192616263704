@import "~bootstrap";
@import "tablefilter.css";
@import "../../public/vendor/dropzone-5.7.0/dist/dropzone.css";
@import "variables";
@import "pages";
@import "components";
@import "core";
@import "menu";
@import "responsive";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~tom-select/dist/css/tom-select.css";
@import "~tom-select/dist/css/tom-select.bootstrap5.css";
@import "~tiny-date-picker/dist/tiny-date-picker.css";


.titleInSelectDropList {
  font-weight: bold;
}

.fillWhite {
  background-color: $white !important;
}

.frameDivision {
  border: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px;

  .titleFrameDivision {
    font-weight: bold;
  }
}

.separator {
  clear: both;
  margin: 15px !important;
}

.checkboxTrue,
.checkboxFalse {
  font-size: 19px;
}

.checkboxTrue {
  color: $success;
}

.checkboxFalse {
  color: $danger;
}

.totalColumns {
  font-weight: bold;
}

.textSuccess,
.textWarning,
.textDanger,
.textDangerForValidation,
.textDangerForDifference,
.textPrimary {
  color: #FFFFFF !important;
}

.textSuccess {
  background-color: $success,
}

.textWarning {
  background-color: $warning,
}

.textDanger,
.textDangerForValidation,
.textDangerForDifference {
  background-color: $danger,
}

.textPrimary {
  background-color: $primary !important;
}

.colorSuccess {
  color: $success;
}

.colorWarning {
  color: $warning;
}

.colorDanger {
  color: $danger;
}

.colorDanger:hover {
  color: $danger;
}

.textColorGreen {
  color: $success;
}

.textColorPink {
  color: #FF69B4;
}

.textColorBlue {
  color: #3292e0;
}

.fillBlue {
  background-color: $primary !important;

  &:hover {
    background-color: $primary !important;
  }
}

.fillPurple {
  background-color: $purple !important;

  &:hover {
    background-color: $purple !important;
  }
}

.table-hover tbody .fillPurple:hover {
  color: white !important;
}

.table-hover tbody .fillBlue:hover {
  color: white !important;
}

.fillBlue,
.fillPurple {
  color: $white;

  td {
    font-weight: bold;
  }
}

.textLeft {
  text-align: left !important;
}

.textCenter {
  text-align: center !important;
}

.textRight {
  text-align: right !important;
}

.validationIconInTableFilter {
  font-size: 22px;
  top: 3px;
  position: relative;
  left: 5px;
}

//FixLineAtTheTopOfTableFilter
.ldiv {
  width: 60% !important;
}

//Fix display button in jquery-confirm
.jconfirm .jconfirm-box .jconfirm-buttons button {
  border: 1px solid rgba(42, 50, 60, 0.2);
}

//Fix cursor in jquery-confirm modal
.jconfirm.jconfirm-modern .jconfirm-box div.jconfirm-title-c {
  cursor: all-scroll;
}

//Fix display first checkbox in alert in stepsConstructionSitePropertyContent
.stepsConstructionSitePropertyContent {
  .checkbox:first-child {
    padding-left: 23px;
  }
}

//Fix jquery-ui-sortable
#sortable {
  tr {
    /*cursor: move;*/
  }

  .ui-state-highlight,
  .ui-widget-content .ui-state-highlight,
  .ui-widget-header .ui-state-highlight {
    background: $primary;
  }

  .ui-state-checked {
    background: $primary;
  }
}

//Fix border radius in summernote
.note-editor {
  border-radius: 2px !important;
}

//Fix display menu dropown on bottom left (for tableFilter)
table {
  tr, td, th {
    overflow-wrap: break-word !important;
    white-space: normal !important;
  }

  thead {
    input[type=checkbox] {
      display: none;
    }

    .flt_checklist_item {
      font-size: 14px !important;

      label {
        color: #444 !important;
        margin: 0px !important;
        line-height: 15px;
      }

      &:hover {
        background-color: $primary !important;

        label {
          color: #FFF !important;
        }
      }
    }

    .flt_checklist_slc_item {
      background-color: #DDD !important;
    }
  }

  .dropdown-menu {
    right: 0;
    left: auto;
  }

  //Fix border on select page / result by page
  .pgSlc,
  .rspg {
    border-bottom: 3px solid #f4f4f4;
  }
}

//Fix display grid_layout on TableFilter
table.tablefilter {
  margin: 0 !important;
}

div.grd_headTblCont {
  //margin-right: 17px !important;
  margin-right: 0 !important;
  border-right: 1px solid #DDD;
}

div.grd_headTblCont table th,
div.grd_tblCont table td {
  overflow: visible !important;
}

.nowrap {
  overflow-wrap: break-word !important;
  white-space: normal !important;
}

//Fix display full width alert no result
.no-results {
  width: 100% !important;
}

//Fix btn-group
.btn-group {
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    margin-left: 0px;
    margin-top: -1px;
  }
}

//Fix size of icons in btn
.fa-fw {
  font-size: 18px;
  position: relative;
  top: 1px;
}

.fa-plus {
  position: relative;
  top: 2px;
}

//Fix size of icons in btn groups
.btn-group {
  .fa-fw {
    margin-right: 7px;
  }
}

//Fix size of icons in tabs
.nav > li > a i {
  font-size: 16px;
}

//Fix border box-shadow on tabs
.nav.nav-tabs {
  box-shadow: none;
}

//Fix padding in tab-content
.nav.nav-tabs + .tab-content {
  padding: 15px !important;
}

//Fix display icons relate to documents
.fa-file-pdf {
  color: #BB0706;
}

.fa-file-word {
  color: #2A5699;
}

.fa-file-powerpoint {
  color: #D04727;
}

.fa-file-excel {
  color: #02723B;
}

.fa-image {
  color: #30b5e1;
}

#client_folder,
#property_folder,
#building_folder,
#user_folder,
#project_folder,
#supplier_folder {
  .fa-file-pdf, .fa-file-word, .fa-file-powerpoint, .fa-file-excel, .fa-image, .fa-download {
    font-size: 25px !important;
  }

  //Fix display in folders
  .fa-download {
    position: relative;
    top: 2px;
  }

  .media-main {
    h4 {
      margin: 0px 0px 10px 0px;
    }

    p {
      margin: 13px 0px 0px 0px;
    }
  }

  hr {
    margin: 15px 0px 15px 0px;
  }
}

#client_document,
#property_document,
#building_document,
#user_document,
#project_document,
#supplier_document {
  .fa-file-pdf, .fa-file-word, .fa-file-powerpoint, .fa-file-excel, .fa-image {
    font-size: 40px !important;
  }
}

#building_folder_financial_cost_dashboard {
  td {
    text-align: right;
  }
}


//Fix title in panel
.panel-title {
  text-align: center;
}

//Fix icon in select dropdown list
select.toRedirect {
  font-family: 'FontAwesome', 'sans-serif';
  width: 62px !important;
}

//Fix display color of breadcrumb
.breadcrumb {
  padding: 8px 15px 8px 0;

  li {
    span {
      color: #fff;
    }

    a {
      span:hover {
        color: $primary;
      }
    }

    &:first-child {
      span {
        margin-bottom: 12px;
        color: #ffffff;
        font-size: 19px;
        font-weight: bold;
        padding-right: 5px;
      }
    }
  }
}

//Fix panel in .containerWidth
.containerWidth {
  .panel {
    //box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid $light;
    background: $light;
    box-shadow: none;

    h4, h5 {
      color: $primary;
    }
  }
}

//Fix label and placeholder form elements
label {
  color: $primary !important;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #bfbfbf !important;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #bfbfbf !important;
  opacity: 1 !important;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #bfbfbf !important;
  opacity: 1 !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #bfbfbf !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #bfbfbf !important;
}

//Fix alert bootstrap
.alert {
  font-weight: bold !important;
  text-align: center !important;
}

//Fix display icon menu
.fa-bars {
  font-size: 21px;
  position: relative;
  top: -3px;
}

//Fix tablefilter
table.TF th:last-child {
  border-right: 1px solid #ddd !important;
}

.fltrow {
  border: 1px solid #ddd !important;
}

table {
  caption {
    padding-top: 0 !important;

    *:focus {
      border-color: inherit !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  th {
    color: $primary !important;
  }

  tr, td, th {
    text-align: center;
    vertical-align: middle !important;

    .descending, .ascending {
      background: none !important;
      display: none !important;
    }

  }
}

.no-results {
  background: none !important;
}

div.tot {
  padding: .5em .5em .5em 0 !important;
}

//Fix sidebar-menu active li a
li.selected {
  a {
    font-weight: bold !important;
  }
}

td.link {
  cursor: pointer !important;
}

//Fix path to intl-tel-input
/*.iti-flag {background-image: url("/vendor/intl-tel-input/img/flags.png");}*/

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  /* .iti-flag {background-image: url("/vendor/intl-tel-input/img/flags@2x.png");}*/
}

.intl-tel-input {
  width: 100%;
}


//Fix display button in table
.btn {
  margin-bottom: 1px;
}

//Fix select2
.select2-selection {
  background-color: #fafafa !important;
  border-radius: 2px !important;
}

.select2-container--bootstrap .select2-selection {
  border: 1px solid #eeeeee !important;
  height: 38px !important;
  box-shadow: none !important;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
  padding-top: 3px !important;
}

.select2-container--bootstrap.select2-container--focus .select2-selection, .select2-container--bootstrap.select2-container--open .select2-selection {
  box-shadow: none !important;
}

.select2-container--bootstrap .select2-search--dropdown .select2-search__field {
  border-radius: 2px !important;
}

.select2-container--bootstrap .select2-dropdown {
  border-color: #eeeeee !important;
}

.select2-results__option {
  min-height: 2em !important;
}

.header__top {
  display: flex;
  justify-content: end;
  width: 100%;
}

body {
  font-size: 14px !important;
}

.table-hover tbody tr:hover {
  color: inherit !important;
}

.font__size__14 {
  font-size: 14px;
}

#sidebar-menu ul ul {
  display: inherit;
}

/*.form__horizontal{
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

.form-group{
    width: 40%;
    margin: 2rem;
}*/

.button__top {
  font-size: 12px;
  margin: 0.2rem;
}

.topbar__top {
  display: flex;
  align-items: center;
  padding-top: 0.2rem;
  flex-direction: row-reverse;
  box-shadow: 0 0 5px $dark;

  img {
    height: 35px;
    width: auto;
    margin-right: 2rem;
    margin-left: 1rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.title__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;

  .icon__menu--container {
    display: flex;

    h1 {
      margin-left: 1rem;
      line-height: inherit;
      font-size: 20px;
      align-items: center;
     /* display: flex;*/
      a{
        color: #2a323c;
        &:hover{
          color: #1b4e81;
        }
      }

    }

    i {
      width: 50px;
      height: 50px;
      box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      color: $primary;
    }

    img {
      width: 50px;
      height: 50px;
      box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .button__title--primary {
    background: #033C73 linear-gradient(180deg, #295988, #033C73) repeat-x;
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #033C73;
    padding: 0.375rem 0.75rem;
    font-size: 0.88rem;
    line-height: 1.5;
    border-radius: 2px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{
      background: $primaryDark;
      cursor: pointer;
    }
  }
}

.topbar__links--container {
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  right: 2rem;
  width: 150px;
  box-shadow: 0 0 5px $dark;

}

.table__layout {
  table-layout: fixed;
}

.flash_bag--container {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1000;

  .flash-notice {
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border: 1px solid $success;
  }

  .flash-danger {
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border: 1px solid $danger;
  }
}

/*New design*/

body {
  background-color: #f1f4f6;
}

.top_bar--container {
  width: 100%;
  position: fixed;
  z-index: 1;
  background-color: #fafcfe;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);

  .top_bar--burger {
    margin-left: 250px;
    font-size: 21px;
    position: relative;
    top: inherit;
    transition: all 0.3s ease-in-out;
    color: #1b4e81;

    &:hover {
      cursor: pointer;
    }

  }

  .top_bar--user {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    /*&:hover > .menu__user--container{
      right: 10px;
    }*/

    .link_to_userMenu{
      display: flex;
      align-items: center;
    }

    .dropdown-toggle:after{
      display: none;
    }

    .top_bar--picture {
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      margin-right: 0.5rem;
      background-position: center;
    }

    .menu__user--container{
      position: absolute;
      /*transition: right 0.5s ease-in-out;*/
      top: 46px;
      right: 0;
      background-color: white;
     /* display: flex;
      flex-direction: column;*/

      li{
        padding: 0.5rem 1rem;
      }

      a {

        color: $primary;
        &:hover{
          color: black;
        }
      }
    }

  }


}


.aside__container {
  width: 250px;
  background: white;
  height: 100vh;
  padding: 1rem;
  position: fixed;
  z-index: 2;
  left: 0;
  transition: all 0.3s ease-in-out;
  padding-top: 0.5rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .aside__top--container {
    display: flex;
    justify-content: center;

    .aside__top--title {
      font-size: 1.5rem;
      text-align: center;
      color: inherit;
      text-align: center;
    }

  }

  .aside__content--container {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;

    .dropdown-toggle:after{
      display: none;
    }

    .aside__block--container {

      .aside__block--title {
        position: relative;
        display: flex;
        justify-content: start;
        padding: 0.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;



        &:hover {
          background-color: $primaryLight;
        }

        .cross__absolute {
          position: absolute;
          right: 20px;
          top: 15px;
          opacity: 1;
        }

        .minus__absolute {
          position: absolute;
          right: 20px;
          top: 15px;
        }

        .cross__opacity {
          opacity: 0;
        }
      }

      &:hover {
        cursor: pointer;
      }


      .chips {
        display: block;
        opacity: 1;
      }


      i {
        color: $lightPrimary;
      }

      span {
        color: $lightDark;
        padding-left: 0.5rem;
      }
    }

    .aside__block--content {
      padding-bottom: 0;
      padding-left: 2.5rem;
      padding-top: 0.5rem;
      position: relative;



      &:before {
        content: '';
        background: #d1f3ff;
        height: 100%;
        width: 3px;
        position: absolute;
        left: 40px;
        top: 0;
      }

      .aside__block--link {
        padding: 0.5rem;
        padding-left: 1rem;

        a {
          color: $lightDark;
        }

        &--active, &:hover {
          background-color: $primaryLight;

          i {
            color: $lightDark;
          }

          span {
            color: $primary;
            padding-left: 0.5rem;
          }

        }
      }

    }


    .aside__block {
      padding: 0.5rem;
      display: flex;
      justify-content: start;
      padding-top: 1rem;
      padding-bottom: 1rem;
      position: relative;

      .cross__absolute {
        position: absolute;
        right: 20px;
        top: 15px;
        opacity: 1;
      }

      .minus__absolute {
        position: absolute;
        right: 20px;
        top: 15px;
      }

      &--active, &:hover {
        background-color: $primaryLight;


        i {
          color: $lightDark;
        }

        span {
          color: $primary;
          padding-left: 0.5rem;
        }

      }

      i {
        color: $lightPrimary;
      }

      span {
        color: $lightDark;
        padding-left: 0.5rem;
      }

    }
  }

  .aside__separator{
    border-top: 1px solid lightgray;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.content__container {
  margin-left: 250px;
  padding-top: 80px;
  transition: margin-left 0.3s ease-in-out;

  table {
    background-color: white;
  }
}

.left__minus250 {
  left: -250px;
}

.left__0 {
  left: 0 !important;
  width: 100%;
}

.margin__left0 {
  margin-left: 0 !important;
}

.blue {
  color: $primary;
}

.black {
  color: black;
}

.background__white--transparent {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
}

h5 {
  font-size: 1rem;
}

.links__container {
  display: flex;
  justify-content: center;

  .link__container {
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    a:hover + .dropdown__links {
      opacity: 1;
      display: flex;
    }

    span:hover + .dropdown__links {
      opacity: 1;
      display: flex;
    }

    .dropdown__links {
      background: #033C73 linear-gradient(180deg, #295988, #033C73) repeat-x;;
      display: none;
      flex-direction: column;
      position: absolute;
      z-index: 2;
      opacity: 0;
      border-radius: 2px;

      &:hover {
        opacity: 1;
        display: flex;
      }

      a {
        padding: 0.5rem;
        color: white;
        border-top: 1px solid;
        &:hover{
          background: $primaryDark;
        }
      }
    }
  }

  .button__title--disable {
    background: #033C73 linear-gradient(180deg, #295988, #033C73) repeat-x;
    display: inline-block;
    font-weight: 400;
    color: gray;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #033C73;
    padding: 0.375rem 0.75rem;
    font-size: 0.88rem;
    line-height: 1.5;
    border-radius: 2px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{
      cursor: not-allowed;
    }
  }
}

.subtitle__container {
  align-items: center;

  .links__container {
    display: flex;
    justify-content: space-between;
  }

  .button__title--primary {
    background: #033C73 linear-gradient(180deg, #295988, #033C73) repeat-x;
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #033C73;
    padding: 0.375rem 0.75rem;
    font-size: 0.88rem;
    line-height: 1.5;
    border-radius: 2px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{
      background: $primaryDark;
      cursor: pointer;
    }
  }
}

.panel__title{
  font-size: 20px;
  font-weight: 400;
}

.box__shadow{
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
}

.subtitle__document--container{
  font-size: 18px;
  text-align: center;

  .button__title--primary {
    background: #033C73 linear-gradient(180deg, #295988, #033C73) repeat-x;
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #033C73;
    padding: 0.375rem 0.75rem;
    font-size: 0.88rem;
    line-height: 1.5;
    border-radius: 2px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{
      background: $primaryDark;
      cursor: pointer;
    }
  }



  .subtitle__previous_page{
    margin-bottom: 2rem;
    padding: 0.5rem;
    background-color: $primary;
  }

  .subtitle__document{
    margin-bottom: 2rem;
    padding: 0.5rem;
    background-color: white;
  }
}

.table__control--height{
  max-height: 500px;
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.table__control--height::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.button__success{

  color: #fff;
  background: #73A839 linear-gradient(180deg, #88b557, #73A839) repeat-x;
  border-color: #73A839;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.88rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover{
    background: #5f8b2f linear-gradient(180deg, #779d4e, #5f8b2f) repeat-x;
    border-color: #59822c;
    cursor: pointer;
  }
}

.button__danger{

  color: #fff;
  background: $danger;
  border-color: $danger;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.88rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover{
    background: #d2332f;
    border-color: #d2332f;
    cursor: pointer;
  }
}

.button__danger--light{

  color: $danger;
  background: #fff;
  border-color: $danger;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.88rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover{
    background: $danger;
    border-color: #d2332f;
    cursor: pointer;
    color: #fff;
  }
}

.button__primary{

  color: #fff;
  background: #033C73 linear-gradient(180deg, #295988, #033C73) repeat-x;
  border: 1px solid #033C73;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.88rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover{

    color: white;
    cursor: pointer;
  }
}

.button__primary--disable {
  background: #033C73 linear-gradient(180deg, #295988, #033C73) repeat-x;
  display: inline-block;
  font-weight: 400;
  color: gray;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #033C73;
  padding: 0.375rem 0.75rem;
  font-size: 0.88rem;
  line-height: 1.5;
  border-radius: 2px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    color: gray;
    cursor: not-allowed;
  }
}

.default__picture{
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 130px;
}

@media (max-width: 1024px) {
  .content__container {
    margin-left: 0px;
  }

  .aside__container {
   left:-250px;
  }

  .top_bar--container {
    z-index: 3;
    .top_bar--burger{
      margin-left: 0px;
    }
  }

  .links__container {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    .link__container{
      margin: 0.1rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      .dropdown__links {
        position: relative;
      }
    }
  }

  .title__container{
    flex-direction: column;
    .icon__menu--container{
      margin-bottom: 0.5rem;
      align-items: center;
      h1{
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

}

/*.wrapper{
  display: flex;
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar{
  min-width: 250px;
  max-width: 250px;
}

#sidebar .active{

}

#sidebar ul.components{
  padding: 20px 0;
}*/

a{
  color: #033C73;
}

.table-hover{
  tbody{
    tr:hover{
      background-color: #d1f3ff;
    }
  }
}

.form__control--input{
  background-color: rgb(250, 250, 250);
  box-shadow: none;
  height: 38px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3);
  border-image: initial;
}

.anchor{
    display: block;
    position: relative;
    top: -50px;
    visibility: hidden;
}

.dropdown__button {
  position: absolute;
  right: 1.2rem;
  top: 10px;
  &:hover{
    cursor: pointer;
  }
}


.conditions__content{
  transition: height 0.7s ease-in-out;
}

.conditions__content--title{
  &:hover{
    cursor: pointer;
  }
}

.conditions__content--hidden{
  opacity: 0;
  height: 0;
}
.conditions__content--visible{
  opacity: inherit;
  height: auto;
}

.icon__td--container{
  position: relative;

  .icon__td{
    font-size: 12px;

    &:hover{
      cursor: pointer;
    }
  }
}

.closeButtonAlert{
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 15px;
  &:hover{
    cursor: pointer;
  }
}

.invoice__display--container{
  div{
    height: 60px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);

    p{
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .fa-file-pdf{
    font-size: 1.6rem;
  }



}

.colorLink {
  color: #1b4e81;
}
