/*
File: Responsive
*/

@media only screen and (max-width: 6000px) and (min-width: 700px) {
  .wrapper.right-bar-enabled {
    .right-bar {
      right: 0;
      z-index: 99;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
  .search-bar {
    display: none !important;
  }
}

@media (max-width: 767px) {
  body {
    overflow-x: hidden;
  }

  .content-page {
    margin-left: 0 !important;
  }
  .enlarged .left.side-menu {
    margin-left: -75px;
  }

  .mobile-sidebar {
    left: 0;
  }

  .mobile-content {
    left: 250px;
    right: -250px;
  }

  .wrapper-page {
    width: 90%;
  }

  .navbar-nav {
    .open {
      .dropdown-menu {
        background-color: $white;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
        left: auto;
        position: absolute;
        right: 0;
      }
    }
  }

  .footer  {
    left: 0 !important;
  }

}

@media (max-width: 620px) {
  .topbar-left {
    width: 70px !important;
  }
  .logo {
    display: none !important;
  }
  .logo-sm {
    display: inline-block !important;
  }
  .page-header-title {
    text-align: center;
    padding: 10px 15px 88px 20px;
  }
}

@media (max-width: 480px) {
  .side-menu {
    z-index: 10 !important;
  }

  .button-menu-mobile {
    display: block;
  }

  .search-bar {
    display: none !important;
  }
}

@media (max-width: 420px) {
  .hide-phone {
    display: none !important;
  }
}



