.topbar {
  background: $white;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  line-height: 45px;

  .topbar-left {
    background-color: $white;
    float: left;
    //height: 70px;
    position: relative;
    width: 240px;
    z-index: 1;
  }
}

.logo, .logo-sm {
  color: $dark!important;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: .03em;
  //line-height: 70px;

  span {
    color: $primary;
    font-family: 'Crete Round', serif;
  }
}

.logo-sm {
  display: none;

  span {
    background-color: #eee;
    height: 44px;
    width: 44px;
    display: block;
    line-height: 44px;
    margin-top: 13px;
  }
}

.navbar-default {
  background-color: $dark;
  border-radius: 0px;
  border: none;
  margin-bottom: 0px;

  .navbar-nav > .open > a {
    background-color: fade($white,10%);
    &:focus {
      background-color: fade($white,10%);
    }
    &:hover {
      background-color: fade($white,10%);
    }
  }
}

.nav > li > a {
  color: $white !important;
  padding: 0px 15px;
  position: relative;
  i {
    font-size: 20px;
  }
  .badge {
    position: absolute;
    right: 8px;
    top: 7px;
    display: block;
    height: 7px;
    width: 7px;
    padding: 0;
    min-width: 1px;
  }
}

.profile {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  img {
    border: 2px solid #edf0f0;
    height: 36px;
    width: 36px;
    float: left;
  }

  .profile-username {
    margin-left: 45px;
    display: block;
    margin-top: 7px;
  }
}

.dropdown-menu-lg {
  width: 270px;

  .list-group {
    margin-bottom: 0;
  }
  .list-group-item {
    padding: 10px 20px;
    border-left: 0;
    border-right: 0;

    &:last-child {
      border-bottom: none;
    }
  }
  .media p {
    color: $muted;
  }
  .media-heading, p {
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 100%;
    font-weight: normal;
    overflow: hidden;
  }
}

.notifi-title {
  color: $white;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0 10px;
  margin-top: -5px;
  background-color: $primary;

  .badge-success {
    background-color: fade($white,50%);
    color: $dark;
    font-weight: bold;
    margin-left: 5px;
  }
}

.navbar-form {
  border: none;
  box-shadow: none;
  padding: 0px;
}

.search-bar {
  background-color: fade($white,10%) !important;
  border: none !important;
  box-shadow: none !important;
  color: $white;
  font-size: 14px;
  height: 42px;
  margin-top: 6px;
  margin-left: 15px;
  border-radius: 30px;
  padding-left: 20px;
}

.btn-search {
  display: none;
}

input.search-bar {
  &::-webkit-input-placeholder {
    color: fade($white,70%);
  }
  &:-moz-placeholder {
    color: fade($white,70%);
  }
  &::-moz-placeholder {
    color: fade($white,70%);
  }
  &:-ms-input-placeholder {
    color: fade($white,70%);
  }
}

.navbar-nav {
  margin: 0;
}

.side-menu {
  bottom: 0;
  top: 0;
  width: 240px;
  z-index: 2;
}

.side-menu.left {
  background: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  position: absolute;
  //top: 70px;
  top: 40px;
  z-index: 99;
}

body.fixed-left {
  .side-menu.left {
    bottom: 50px;
    height: $height;
    margin-bottom: -70px;
    margin-top: 0px;
    padding-bottom: 70px;
    position: fixed;
  }
}

.content-page {
  margin-left: 240px;
  overflow: hidden;
}

.content-page > .content {
  margin-bottom: 60px;
  //margin-top: 100px;
  margin-top: 140px;
  padding: 20px 5px 15px 5px;
}

.button-menu-mobile {
  background: $primary;
  border: none;
  color: fade($white,80%);
  font-size: 28px;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  margin-top: 14px;
  margin-left: 15px;
  &:hover {
    color: $white;
  }
}

.sidebar-inner {
  height: $height;
}

#sidebar-menu, #sidebar-menu ul, #sidebar-menu li, #sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

.notification-icon-box {
  height: 36px;
  width: 36px;
  padding: 0 !important;
  border: 2px solid fade($white,50%) !important;
  line-height: 35px !important;
  text-align: center;
  border-radius: 50%;
  margin: 17px 5px;

  i {
    font-size: 16px !important;
  }
}
#sidebar-menu {
  background-color: $white;
  padding-bottom: 50px;
  width: 100%;
  a {
    line-height: 1.3;
  }
  .badge {
    margin-top: 4px;
  }
  ul {
    ul {
      display: none;
      li {
        border-top: 0;
      }
      li.active {
        a {
          color: $dark;
        }
      }
      a {
        color: fade($dark,80%);
        display: block;
        padding: 10px 25px 10px 65px;

        &:hover {
          color: $dark;
        }
        i {
          margin-right: 5px;
        }
      }
      ul {
        a {
          padding-left: 80px;
        }
      }
    }
  }
}

#sidebar-menu > ul > li > a {
  color: $dark;
  display: block;
  padding: 15px 25px;
  background-color: $white;
  font-size: 15px;
  height: 50px !important;

  &:hover {
    background-color: darken($white,5%);
    text-decoration: none;
  }
}

#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

#sidebar-menu > ul > li > a > i {
  display: inline-block;
  font-size: 18px;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  color: lighten($dark,15%);
}

#sidebar-menu > ul > li > a > i.i-right {
  float: right;
  margin: 3px 0 0 0;
}

#sidebar-menu > ul > li > a.active {
  color: $primary;
  font-weight: 900;

  i {
    color: $primary;
  }
}


/*.subdrop {
  background: darken($white,5%) !important;
}*/

#wrapper.enlarged {
  #sidebar-menu {
    ul {
      ul {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }
  .left.side-menu {
    padding-top: 0;
    //width: 70px;
    width: 0px;
    z-index: 5;
    #sidebar-menu > ul > li > a {

      i {
        margin-right: 20px !important;
        margin-left: 0 !important;
        font-size: 22px;
      }
    }
    span.pull-right {
      display: none !important;
    }
    #sidebar-menu {
      ul > li {
        position: relative;
        white-space: nowrap;
        &:hover > a {
          position: relative;
          width: 260px;
          background-color: darken($white,5%);
        }
        &:hover > ul {
          display: block;
          left: 70px;
          position: absolute;
          width: 190px;
          a {
            background: $white;
            border: none;
            box-shadow: none;
            padding-left: 15px;
            position: relative;
            width: 190px;
            z-index: 6;

            &:hover {
              color: $dark;
            }
          }
        }
        &:hover {
          a {
            span {
              display: inline;
            }
          }
        }
      }
      a.subdrop {
        color: $dark !important;
      }
      ul > li > ul {
        display: none;
      }
      ul {
        ul {
          li {
            &:hover > ul {
              display: block;
              left: 190px;
              margin-top: -36px;
              position: absolute;
              width: 190px;
            }
          }
          li > a {
            span.pull-right {
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              position: absolute;
              right: 20px;
              top: 12px;
              transform: rotate(270deg);
            }
          }
          li.active {
            a{
              color: $dark;
            }
          }
        }
      }
      ul > li > a {
        span {
          display: none;
          padding-left: 10px;
        }
      }
    }
    .user-details {
      display: none;
    }
  }
  .content-page {
    //margin-left: 70px;
    margin-left: 0px;
  }
  .topbar {
    .topbar-left {
      width: 70px !important;
      .logo {
        display: none;
        opacity: 0;
      }
      .logo-sm {
        display: inline-block;
      }
    }
  }
}

#wrapper.enlarged .footer {
  left: 70px;
}

.user-details {
  min-height: 80px;
  padding: 20px;
  position: relative;
  img {
    position: relative;
    z-index: 9999;
    height: 88px;
    width: 88px;
  }
  .user-info {
    text-align: center;

    a.dropdown-toggle {
      color: $dark;
      display: block;
      font-size: 18px;
      font-weight: 700;
      padding-top: 5px;
      margin-bottom: 5px;
    }

    .text-success {
      color: $primary;
    }
  }
}

#wrapper.right-bar-enabled {
  .right-bar {
    right: 0;
  }
  .left-layout {
    left: 0;
  }
}

.side-bar.right-bar {
  float: right !important;
  right: -266px;
  top: 70px;
}

.side-bar {
  -moz-transition: all 200ms ease-out;
  -webkit-transition: all 200ms ease-out;
  background-color: $white;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
  display: block;
  float: left;
  height: $height;
  overflow-y: auto;
  position: fixed;
  transition: all 200ms ease-out;
  width: 240px;
}

.right-bar {
  background: $white !important;
  z-index: 99 !important;
  h4 {
    border-bottom: 1px solid $light;
    padding-bottom: 10px;
  }
}

.contact-list {
  max-height: 600px;
  .list-group-item {
    border: none;
    &:hover {
      background: $light5;
    }
  }
  i.offline {
    color: $danger;
  }
  i.away {
    color: $warning;
  }
}



.page-header-title {
  /*background-color: $dark;
  margin: -30px -5px 23px -5px;*/
  padding: 10px 15px 100px 20px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  .breadcrumb {
    margin-bottom: 0;
  }
  .page-title {
    margin-bottom: 12px;
    color: $white;
    font-size: 22px;
  }
}

.page-content-wrapper {
  margin-top: -100px;

}

body.fixed-left-void {
  //min-height: 1024px;
}



//Small Menu
.small-menu {
  .side-menu,.topbar-left {
    width: 190px;

    .badge {
      display: none;
    }
  }
  #sidebar-menu > ul > li > a {
    height: auto !important;
    text-align: center;
  }
  #sidebar-menu > ul > li > a > i {
    font-size: 24px;
    line-height: 26px;
    display: block;
    width: auto;
    margin: 0;
  }
  #sidebar-menu > ul > li > a > span.pull-right {
    display: none !important;
  }
  #sidebar-menu ul ul a {
    padding: 10px;
    text-align: center;
  }
  .content-page {
    margin-left: 190px;
  }
  #wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a {
    text-align: left;
    height: 50px !important;
  }
  #wrapper.enlarged .left.side-menu #sidebar-menu ul ul a{
    text-align: left;
  }
  #wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a i {
    margin-right: 20px !important;
    margin-left: 0 !important;
    font-size: 22px;
    display: inline-block;
    line-height: 17px;
    text-align: center;
    vertical-align: middle;
    width: 20px;
  }
}

.layout2 {
  .topbar-left {
    background-color: $dark;
    .logo, .logo-sm {
      color: $white !important;
    }
  }
  .page-header-title {
    background-color: transparent;
    box-shadow: none;
    padding: 10px 15px 85px 20px;
    .page-title {
      color: $dark;
    }
  }
}
