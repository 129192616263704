/*
File: Pages
*/

/*
  - Sweet Alerts
  - Maps
  - Email
  - Timeline
  - Charts
  - Count Down
  - Gallery
  - Maintenance
  - Account Pages
*/

/* =========== */
/* Sweet Alert */
/* =========== */

.sweet-alert {

  h2 {
    font-size: 24px;
    position: relative;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }
  .icon.success  {
    .placeholder {
      border: 4px solid fade($success,30%);
    }
    .line {
      background-color: $success;
    }
  }
  .icon.warning {
    border-color: $warning;
  }
  .icon.info {
    border-color: $info;
  }
  .btn-warning:focus,.btn-info:focus,.btn-success:focus,.btn-danger:focus,.btn-default:focus {
    box-shadow: none;
  }
  .btn-lg {
    font-size: 15px !important;
    padding: 6px 14px !important;
  }
}
.sweet-alert .icon.error {
  border-color: $danger;
}
.sweet-alert .icon.error .line {
  background-color: $danger;
}
.sweet-alert .icon.warning .body,.sweet-alert .icon.warning .dot {
  background-color: $warning;
}


/* ==============
  Timeline
===================*/


.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  content: '';
  display: table;
  clear: both;
}
#cd-timeline {
  margin-bottom: 2em;
  margin-top: 2em;
  padding: 2em 0;
  position: relative;
  &::before {
    background: $light-alt;
    content: '';
    height: 100%;
    left: 18px;
    position: absolute;
    top: 0;
    width: 4px;
  }
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-bottom: 3em;
    margin-top: 3em;
    &::before {
      left: 50%;
      margin-left: -2px;
    }
  }
}

.cd-timeline-block {
  margin: 2em 0;
  position: relative;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}
.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #fff;
}
.cd-timeline-img.cd-success {
  background: $success;
}
.cd-timeline-img.cd-info {
  background: $info;
}
.cd-timeline-img.cd-pink {
  background: $pink;
}
.cd-timeline-img.cd-danger {
  background: $danger;
}
.cd-timeline-img.cd-primary {
  background: $primary;
}
.cd-timeline-img.cd-warning {
  background: $warning;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    line-height: 60px;
    left: 50%;
    margin-left: -30px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}


.cd-timeline-content {
  -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  background: $light-alt;
  border-radius: 0;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
  margin-left: 60px;
  padding: 1em;
  position: relative;
  img {
    display: block;
    width: 100%;
  }
  &:after {
    clear: both;
    content: "";
    display: table;
  }
  h2 {
    margin-top: 0;
  }
  p {
    color: #666666;
    font-size: 14px;
    margin: 10px 0px 10px 0px;
  }
  .cd-read-more {
    background: #acb7c0;
    border-radius: 0.25em;
    color: white;
    display: inline-block;
    float: right;
    font-size: 14px;
    padding: .8em 1em;
  }
  .cd-date {
    display: inline-block;
    font-size: 14px;
  }
  h3 {
    font-size: 21px;
    margin: 0px;
  }
}
.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7;
}
.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 12px solid transparent;
  border-right: 12px solid $light-alt;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: $light-alt;
  }
  .cd-timeline-content .cd-read-more {
    float: left;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: $light-alt;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}


/* ==============
  Charts
===================*/


.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}

.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;

  &:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .8em;
  }
}


#flotTip {
  padding: 4px 8px;
  background-color: #000000;
  z-index: 100;
  color: #ffffff;
  opacity: .7;
  font-size: 12px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/* ==============
  Email
===================*/


.mails {
  a {
    color: $lightdark4;
  }
  .checkbox {
    margin-bottom: 0px;
    margin-top: 0px;
    vertical-align: middle;
    label {
      min-height: 16px;
    }
  }
}

/* ==============
  Count Down
===================*/


.home-wrapper {
  margin: 10% 0px;
}

.home-text {
  font-family: 'Nunito', sans-serif;
}

.lj-countdown {
  color: $primary;
  margin-top: 40px;
  text-align: center;
  div {
    display: inline-block;
    span {
      display: block;
      width: 150px;
      &:first-child {
        font-size: 3em;
        font-weight: 700;
        height: 48px;
        line-height: 48px;
      }
      &:last-child {
        color: #333333;
        font-size: 0.9em;
        height: 25px;
        line-height: 25px;
      }
    }
  }
}

.lj-countdown > * {
  text-align: center;
}



/* ==============
  Gallery
===================*/

.portfolioFilter {
  a {
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease-out;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    color: #333333;
    padding: 5px 10px;
    transition: all 0.3s ease-out;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  a.current {
    background-color: $primary;
    color: $white;
  }
}

.thumb {
  background-color: $white;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  width: $width;
}

.thumb-img {
  border-radius: 2px;
  overflow: hidden;
  width: $width;
}

.gal-detail {
  h4 {
    margin-top: 16px;
  }
}

/* ==============
  Maintenance
===================*/


.icon-main {
  font-size: 88px;
  margin-bottom: 50px;
}

.maintenance-page {
  margin: 10% 0%;
}

.mainten-box {
  margin-bottom: 30px;
  .text-m-mode {
    margin: 0px auto;
    width: 80%;
  }
}

.media-main {
  a.pull-left {
    width: 100px;
  }
  .info {
    color: $dark;
    overflow: hidden;
    h4 {
      margin-bottom: 5px;
    }
  }
}

.user-card {
  p.info-text {
    line-height: 26px;
    margin-top: 15px;
  }
}


/* ==============
  Maps
===================*/

.gmaps, .gmaps-panaroma {
  height: 300px;
  background: $light;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: $white;
  font-size: 16px;
  line-height: 40px;
  background: $primary;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid $primary;
}

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid $primary;
}



/* ==============
  Account Pages
===================*/
.accountbg {
  //background: url("../images/restaurant.png");
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.wrapper-page {
  margin: 7.5% auto;
  width: 420px;
  position: relative;
}

.panel-pages {
  border: 4px solid #eee !important;

  .logo-admin {
    font-size: 28px;
  }
  .panel-body {
    padding: 25px 30px;
  }
  .panel-heading {
    -moz-border-radius: 6px 6px 0px 0px;
    -webkit-border-radius: 6px 6px 0px 0px;
    border-radius: 6px 6px 0px 0px;
    padding: 40px 20px;
    position: relative;
    h3 {
      position: relative;
      z-index: 999;
    }
  }
}

.user-thumb {
  position: relative;
  z-index: 999;
  img {
    height: 88px;
    margin: 0 auto;
    width: 88px;
  }
}

.ex-page-content {
  h1 {
    font-size: 98px;
    font-weight: 700;
    line-height: 150px;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
  }
}


/* ==== =====
Invoice
============= */

.table > thead > tr > .no-line {
  border-bottom: none;
}

.table > tbody > tr > .no-line {
  border-top: none;
}
.table > tbody > tr > .thick-line {
  border-top: 2px solid;
}



